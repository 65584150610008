<template>
  <link rel="stylesheet" href="https://cdn.staticfile.org/font-awesome/4.7.0/css/font-awesome.css">
  <div class="bac">
    <div class="top">
      <div class="left">
        <div class="leftTop">
          <p>{{ this.Info.projectShowInfoPage.typeSecond }}</p>
          <p>{{ this.Info.projectShowInfoPage.projectName }}</p>
        </div>
        <div class="leftBottom">
          <p>需求领域：{{ this.Info.projectShowInfoPage.typeSecond }}</p>
          <p>开始时间：{{ this.Info.projectShowInfoPage.publishTime2 }}</p>
          <p>项目预算：￥{{ this.Info.projectShowInfoPage.budget }}</p>
        </div>
      </div>
      <div class="right">
        <div class="itemButton">
          <button>正在设计</button>
          <button>正在服务</button>
          <button>正在保障</button>
        </div>

        <div class="right_line">
          <p >当前进度：</p>
          <div class="circle">
          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">
          </div>
        </div>

        <div class="line_content">
          <p>客户发布</p>
          <p>任务报名</p>
          <p>客户选标</p>
          <p>中标确定</p>
          <p>签订协议</p>
          <p>服务阶段</p>
          <p>平台质检</p>
          <p>验收付款</p>
          <p>评价结束</p>
        </div>
      </div>
    </div>
    <div class="listBac">
      <div class="first_draft">
        <div>初稿方案</div>
        <button>点击查看</button>

      </div>
      <div class="first_draft">
        <div>提案文件</div>
        <button @click="download('proposalFile')">点击查看</button>
        <button @click="showRejectWindow('proposalFile')">驳回</button>
      </div>
      <div class="first_draft">
        <div>设计效果稿</div>
        <button @click="download('designEffectDraft')">点击查看</button>
        <button @click="showRejectWindow('designEffectDraft')">驳回</button>
      </div>
      <div class="first_draft">
        <div>设计源文件</div>
        <button @click="download('designSourceFile')">点击查看</button>
        <button @click="showRejectWindow('designSourceFile')">驳回</button>
      </div>
      <button id="finish">完成项目</button>

      <div class="pop_up1 pop_style" v-show="showReject === 1">
        <p>请填写驳回原因</p>
        <textarea v-model="rejectForm.reason">请输入</textarea>
        <button @click="reject" >确定</button>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "CheckFiles",
  data(){
    return {
      ID: {
        pid: 1,
      },
      showReject:0,
      Info: {
        projectShowInfoPage: {
          id: '',
          projectName: '',
          budget: '',
          typeSecond: '',
          publishTime: '',
          publishTime2: '',
        },
      },
      downloadForm:{
        pid:'',
        deliverableType:'',
      },
      rejectForm:{
        pid:'',
        deliverableType:'',
        reason:'',
      }
    }
  },
  mounted(){
    this.getInfo();
    this.getFile();
  },
  methods:{
    getInfo(){
      this.ID.pid = parseInt(this.$route.query.projectId);
      this.$http({
        method: 'post',
        url: '/showProjectMessage',
        data: this.ID
      }).then(res => {
          this.Info = res.data;
      })
    },
    getFile(){
      this.$http({
        method: 'post',
        url: '/getDeliverableType',
        data: this.ID
      }).then(res => {
      })
    },
    download(index){
      this.downloadForm.pid = this.ID.pid;
      this.downloadForm.deliverableType = index;
      this.$http({
        method: 'post',
        url: '/deliverable/download',
        data: this.downloadForm
      }).then(res => {
        if(res.data.code === 0){
          this.$notify({
            title: '下载失败',
            message: res.data.message,
            type: 'warning'
          });
        }else {
          window.location.href = res.data;
        }
      })
    },
    showRejectWindow(index){
      this.rejectForm.deliverableType = index;
      this.showReject = 1;
    },
    reject(){
      this.rejectForm.pid = this.ID.pid;
      this.$http({
        method: 'post',
        url: '/deliverable/refuseDeliverable',
        data: this.rejectForm,
      }).then(res => {
        if(res.data.code === 1){
          this.$notify({
            title: '驳回成功',
            message:'',
            type: 'warning'
          });
        }
      })
      this.showReject = 0;
    }
  }
}
</script>

<style scoped>
.bac{
  width: 1920px;
  padding-left: 298px;
  padding-top: 50px;
  background-color: #f5f5f5;
}


.top{
  position: relative;
  width: 1462px;
  height: 330px;
  /*border-radius: 5px;*/
  /*background-color: pink;*/
  padding-top: 50px;
  /*margin: 0 auto 50px;*/
  background-color: #ffffff;
  /*box-shadow: 0 0 5px rgba(0,0,0,.2);*/
  margin-left: 70px;
  margin-bottom: 35px;

}

.top .label{
  position: absolute;
  width: 77px;
  height: 50px;
  top: 37px;
  right: 0px;
}

.top .label img{
  width: 100%;
  height: 100%;
}

.left{
  float: left;
  width: 298px;
  height: 244px;
  margin-left: 60px;
  border-right: 1px solid #a4a4a4;

}

.top .left_top p:nth-child(1){
  height: 24px;
  color: #212121;
  font-size: 24px;
  margin-bottom: 20px;
}

.top .left_top p:nth-child(2){
  height: 36px;
  color: #212121;
  font-size: 36px;
  margin-bottom: 65px;
}

.top .leftBottom{
  height: 86px;
  font-size: 18px;
  color: #a4a4a4;

}

.top .leftBottom p{
  height: 18px;
  margin-bottom: 19px;
}

.right{
  float: left;
  height: 244px;
  margin-left: 50px;

}

.right .itemButton{
  margin-bottom: 35px;
}

.right .itemButton button{
  width: 90px;
  height: 30px;
  font-size: 14px;
  background-color: #ff655f;
  margin-right: 10px;
  color: #ffffff;
  border-radius: 5px;
}

.right .right_line{
  height: 110px;
}

.right .right_line p{
  font-size: 18px;
  color: #212121;
  margin-bottom: 40px;
}

.right .right_line i{
  text-align: center;
  width: 97px;
}

.right .right_line div{
  float:left;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ed404a;
}

.right .right_line div:nth-of-type(n+5){
  background-color: grey;
}


.right .right_line i{
  float: left;
  height: 20px;
  color: #ed404a;
  letter-spacing: 2px;
  font-size: 15px;
  margin-top: -2px;
}

.right .right_line .circle:nth-child(2){
  margin-left: 30px;
}

.right .line_content p{
  float: left;
  color: #212121;
  font-size: 18px;
  margin-right: 45px;
}

.right .line_content{
  height: 18px;
  margin-bottom: 30px;
}

.right .remind {
  position: relative;
  height: 14px;
}

.right .remind p{
  float: left;
  font-size: 14px;
  color: #adadad;
}

.right .remind i{
  float: left;
  margin-right: 5px;
}

.right .remind .eye{
  float: right;
  width: 45px;
  height: 18px;
  position: absolute;
  right: 120px;
}

.right .remind .eye span{
  height: 18px;
  float: left;
  font-size: 14px;
  line-height: 18px;
}



.right .remind .group{
  float: right;
  width: 45px;
  height: 18px;
  position: absolute;
  right: 60px;
}

.right .remind .group span{
  height: 18px;
  float: left;
  font-size: 14px;
  line-height: 18px;
}

.user_card_bottom{
  width: 1450px;
  height: 45px;
  background-color: #ffffff;
}

.user_card_bottom .real-name{
  float: left;
  width: 310px;
  height: 18px;
  margin-right: 643px;
  margin-left: 50px;
  border: 1px solid transparent;
  /*margin-top: 25px;*/
}

.user_card_bottom .real-name i{
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #ec414d;
  text-align: center;
  color: #ffffff;
  margin-top: 3px;
}


.user_card_bottom .real-name p{
  float: left;
  font-size: 12px;
  color:#acaeab;
}

.user_card_bottom number{
  float: right;
  height: 18px;
}

.user_card_bottom .number p{
  float: left;
  font-size: 16px;
  color: #212121;
  margin-right: 38px;
}

.user_card_bottom .number div{
  float: left;
  font-size: 16px;
  color: #ec414d;
  margin-right: 90px;
}

.listBac{
  position: relative;
  width: 1460px;
  height: 472px;
  margin-left: 70px;
  background-color: #ffffff;
  padding-top: 55px;
}

.listBac .first_draft{
  width: 1460px;
  height: 60px;
  padding-left: 150px;
  margin-bottom: 25px;
  /*background-color: pink;*/

}

.listBac .first_draft div{
  float: left;
  width: 420px;
  height: 60px;
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  line-height: 60px;
  text-align: center;
  color: #212121;
  margin-right: 40px;
  font-size: 24px;
  font-weight: 500;
}

.listBac .first_draft button{
  width: 168px;
  height: 60px;
  border-radius: 6px;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  margin-right: 40px;
  background-color: #fb685e;
}

.listBac .first_draft button:nth-of-type(2){
  width: 112px;
  background-color: #ababad;
}

.listBac #finish{
  position: absolute;
  right: 95px;
  bottom: 58px;
  width: 214px;
  height: 60px;
  font-size: 26px;
  color: #ffffff;
  background-color: #fb685e;
  border-radius: 5px;
}

.pop_style{
  position: absolute;
  right: 435px;
  bottom: 324px;
  width: 445px;
  height: 245px;
  border: 3px solid #adadad;
  padding-top: 20px;
  background-color: #fdfffe;
  text-align: center;
}

.pop_style p{
  /*margin: auto 0;*/
  height: 22px;
  color: #212121;
  margin-bottom: 22px;
  font-size: 24px;
  font-weight: 500;
}

.pop_style textarea{
  display: inline-block;
  width: 404px;
  height: 104px;
  color: #adadad;
  padding-top: 16px;
  padding-left: 12px;
  margin-bottom: 20px;
  /*background-color: pink;*/
  border: 2px solid #adadad;
  font-size: 18px;
  /*点击后不出现内部黑框效果*/
  outline: none;
  /*不允许文本框被随意拖动*/
  resize:none;
}

.pop_style button{
  width: 68px;
  height: 26px;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  margin-left: 335px;
  background-color: #ff5d5e;
}







</style>